import React from "react"
import Cursor from "../components/common/Cursor"
import ArrowZ from "../components/common/ArrowZ"

export default function Ie() {
  // let title = "IE"
  // let description = "お使いのブラウザではこのサイトは閲覧できません"
  let headTtl = "Hi, IT'S SO YESTERDAY."

  return (
    <div className="contents">
      <div id="page">
        <div id="ie" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Cursor />
              <section className="p-common-kv p-ie-kv">
                <div className="p-common-kv__wrapper">
                  <div className="p-common-kv__head">
                    <h1 className="p-common-kv__head-ttl">{headTtl}</h1>
                  </div>
                </div>
              </section>
              <section className="p-ie-main">
                <div className="p-ie-main__wrapper">
                  <div className="p-ie-main__content">
                    <p className="c-txt__main p-ie-main__content-txt">
                      開発元のMicrosoftからも危険とされている{" "}
                      <span className="p-ie-main__content-txt-point">
                        Internet Explorer
                        を使ってCRISPのサイトを閲覧することは出来ません。
                      </span>{" "}
                      これを機にInternet Explorerから、Google Chrome
                      ブラウザに乗り換えましょう！
                    </p>
                  </div>
                  <a
                    className="js-hover p-ie-main__link"
                    data-hover-in-time="600"
                    data-hover-out-time="650"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.google.com/chrome/"
                  >
                    <div className="c-link__main p-ie-main__link-wrapper">
                      <span className="p-ie-main__link-span">
                        <span className="c-link__main-inner">
                          Google Chrome に乗り換える
                        </span>
                        <ArrowZ />
                      </span>
                    </div>
                  </a>
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
